.page {
  h1, h2 {
    font-size: 23px;
  }

  padding-top: 24px;
}

.form-controls {
  button + button {
    margin-left: 12px;
  }
}

.template {
  display: none;
}

.selection {
  background: #cdcdcd;
  border: 2px solid #cdcdcd;
  margin-bottom: 30px; 
  
  :global(.form-group) {
    padding: 30px 20px;
    margin-bottom: 0;
  }

  .select {
    background: #fff;
  }
}

.hidden {
  visibility: hidden;
}

.regular {
  font-weight: normal;
}

.help-text {
  position: relative;
  font-size: 13px;
  top: 2px;
  left: 115px;

  a {
    &::active,
    &::link,
    &:visited,
    &:hover {
      color: #043287
    }
  }
}
.ach-help-text {
  position: relative;
  font-size: 16px;
  top: 2px;
  left: 115px;
}

.cc-front {
  display: block;
  width: 50%;
  float: left;
  padding-left: 10px;
}

.cc-back {
  display: block;
  width: 50%;
  float: left;
}

.cvc-container {
  width: 100%;
  overflow: hidden;
  
  p {
    font-size: 13px;
    margin: 00;
  }

  p.title {
    font-weight: bold;
    height: 38px;
  }

  p.note {
    margin-top: -12px;
    font-size: 13px;
  }
}

.cc-container {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  width: 500px;
  margin-left: 10px;
  overflow: hidden;

  li {
    display: block;
    float: left;
    width: 50px;
    height: 30px;
    margin-left: 0px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  li + li {
    margin-left: 3px;
  }
}

.payment-amount {
  display: flex;

  .total {
    text-align: center;
    padding: 10px;
    height: 70px;
    float: left;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(205,205,205,1);
    -moz-box-shadow: 0px 0px 5px 1px rgba(205,205,205,1);
    box-shadow: 0px 0px 5px 1px rgba(205,205,205,1);

    p {
      border-top: 5px #006298 solid;
      font-size: 30px;
      margin: 0;
      padding: 0;
    }
  }

  .policy {
    float: left;
    padding: 0 0 0 20px;
    font-size: 13px;
  }
}

.instructions {
  text-align: right;
  margin: 0;

  sup {
    color: #c13145;
  }
}
